
export const AppMenus = {
    
	navbarTopRight: [],
	navbarTopLeft: [],
	navbarSideLeft: [
  {
    "to": "/home",
    "label": "Home",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/clientes",
    "label": "Clientes",
    "icon": "pi pi-users",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/secciones",
    "label": "Productos",
    "icon": "pi pi-database",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "",
    "label": "Cotizaciones",
    "icon": "pi pi-money-bill",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/cotizaciones",
        "label": "Listado general",
        "icon": "pi pi-money-bill",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/cotizaciones/pendientesdepago",
        "label": "Pendientes de pago",
        "icon": "pi pi-dollar",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/cotizaciones/pendientesdefacturar",
        "label": "Pendientes de facturar",
        "icon": "pi pi-file-edit",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/cotizaciones/pendientesdedespacho",
        "label": "Pendientes de despacho",
        "icon": "pi pi-send",
        "iconcolor": "",
        "target": "",
        
      }
    ]
  },
  {
    "to": "",
    "label": "Ordenes",
    "icon": "pi pi-print",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/ordenesproduccion",
        "label": "Ordenes en proceso",
        "icon": "pi pi-tablet",
        "iconcolor": "",
        "target": "",
        
      }
    ]
  },
  {
    "to": "",
    "label": "Configuraciones",
    "icon": "pi pi-cog",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/tipodocumentos",
        "label": "Tipo documentos",
        "icon": "pi pi-id-card",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/procesosinternos",
        "label": "Procesos internos",
        "icon": "pi pi-history",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/cotizacionestados",
        "label": "Estados cotizaciones",
        "icon": "pi pi-file-edit",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/ordenesestados",
        "label": "Estados ordenes",
        "icon": "pi pi-file-edit",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/metodospago",
        "label": "Metodos de pago",
        "icon": "pi pi-dollar",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/geopaises",
        "label": "Paises",
        "icon": "pi pi-flag",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/geodepartamentos",
        "label": "Departamentos / estados",
        "icon": "pi pi-globe",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/geociudades",
        "label": "Ciudades",
        "icon": "pi pi-map-marker",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/transportadoras",
        "label": "Transportadoras",
        "icon": "pi pi-car",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/sedes",
        "label": "Sedes",
        "icon": "pi pi-th-large",
        "iconcolor": "",
        "target": "",
        
      }
    ]
  },
  {
    "to": "",
    "label": "Sistema",
    "icon": "pi pi-database",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/usuarios",
        "label": "Usuarios",
        "icon": "pi pi-users",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/roles",
        "label": "Roles",
        "icon": "pi pi-key",
        "iconcolor": "",
        "target": "",
        
      }
    ]
  },
  {
    "to": "/instructivos",
    "label": "Instructivos",
    "icon": "pi pi-info-circle",
    "iconcolor": "",
    "target": "",
    
  }
],
	tipo: [    
{value: "Facturacion", label: "Facturación"},
	{value: "Envio", label: "Envio"},
	{value: "Otros", label: "Otros"}
    ],
	estado: [    
{value: "Activo", label: "Activo"},
	{value: "Inactivo", label: "Inactivo"}
    ],
	facturaElectonica: [    
{value: "Si", label: "Si"},
	{value: "No", label: "No"}
    ],
	tipoPago: [    
{value: "Pago total", label: "Pago total"},
	{value: "Pago parcial", label: "Pago parcial"}
    ],
	estado2: [    
{value: "En proceso", label: "En proceso"},
	{value: "Terminado", label: "Terminado"}
    ],
	tipo2: [    
{value: "Portada", label: "Portada"},
	{value: "Otros", label: "Otros"}
    ],
	origen: [    
{value: "Cotizacion", label: "Cotizacion"},
	{value: "Produccion", label: "Produccion"}
    ],
	tipoSeguimiento: [    
{value: "Llamada", label: "Llamada"},
	{value: "Nota", label: "Nota"}
    ],
	estadoSeguimiento: [    
{value: "Cerrado", label: "Cerrado"},
	{value: "Pendiente", label: "Pendiente"}
    ],
	unidadMedida: [    
{value: "Perimetro", label: "Perimetro"},
	{value: "Area", label: "Area"},
	{value: "Volumen", label: "Volumen"}
    ],
	tipoUsuario: [    
{value: "Usuario", label: "Usuario"},
	{value: "Cliente", label: "Cliente"}
    ],
	todasLasSedes: [    
{value: "si", label: "si"},
	{value: "no", label: "no"}
    ],

    exportFormats: {
        print: {
			label: 'Print',
            icon: 'pi pi-print',
            type: 'print',
            ext: 'print',
        },
        pdf: {
			label: 'Pdf',
			
            icon: 'pi pi-file-pdf',
            type: 'pdf',
            ext: 'pdf',
        },
        excel: {
			label: 'Excel',
            icon: 'pi pi-file-excel',
            type: 'excel',
            ext: 'xlsx',
        },
        csv: {
			label: 'Csv',
            icon: 'pi pi-table',
            type: 'csv',
            ext: 'csv',
        },
    },
    
}