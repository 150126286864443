
import { createRouter, createWebHashHistory } from 'vue-router';

import { useAuth } from 'src/composables/auth';


function passRouteToProps(route){
	return {
		queryParams: route.query,
		fieldName: route.params.fieldName, 
		fieldValue: route.params.fieldValue
	}
}


let routes = [
	//Dashboard routes


//audits routes

//clientecontactos routes
			{
				path: '/clientecontactos/:fieldName?/:fieldValue?',
				name: 'clientecontactoslist',
				component: () => import('./pages/clientecontactos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/clientecontactos/view/:id', 
				name: 'clientecontactosview', 
				component: () => import('./pages/clientecontactos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/clientecontactos/add', 
				name: 'clientecontactosadd', 
				component: () => import('./pages/clientecontactos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/clientecontactos/edit/:id', 
				name: 'clientecontactosedit', 
				component: () => import('./pages/clientecontactos/edit.vue'), 
				props: true
			},
		

//clientes routes
			{
				path: '/clientes/:fieldName?/:fieldValue?',
				name: 'clienteslist',
				component: () => import('./pages/clientes/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/clientes/view/:id', 
				name: 'clientesview', 
				component: () => import('./pages/clientes/view.vue'), 
				props: true
			},
		
			{ 
				path: '/clientes/add', 
				name: 'clientesadd', 
				component: () => import('./pages/clientes/add.vue'), 
				props: true
			},
	
			{ 
				path: '/clientes/edit/:id', 
				name: 'clientesedit', 
				component: () => import('./pages/clientes/edit.vue'), 
				props: true
			},
		

//cotizaciondetalle routes
			{
				path: '/cotizaciondetalle/:fieldName?/:fieldValue?',
				name: 'cotizaciondetallelist',
				component: () => import('./pages/cotizaciondetalle/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/cotizaciondetalle/view/:id', 
				name: 'cotizaciondetalleview', 
				component: () => import('./pages/cotizaciondetalle/view.vue'), 
				props: true
			},
		
			{ 
				path: '/cotizaciondetalle/add', 
				name: 'cotizaciondetalleadd', 
				component: () => import('./pages/cotizaciondetalle/add.vue'), 
				props: true
			},
	
			{ 
				path: '/cotizaciondetalle/edit/:id', 
				name: 'cotizaciondetalleedit', 
				component: () => import('./pages/cotizaciondetalle/edit.vue'), 
				props: true
			},
		

//cotizaciondocumentos routes
			{
				path: '/cotizaciondocumentos/:fieldName?/:fieldValue?',
				name: 'cotizaciondocumentoslist',
				component: () => import('./pages/cotizaciondocumentos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/cotizaciondocumentos/view/:id', 
				name: 'cotizaciondocumentosview', 
				component: () => import('./pages/cotizaciondocumentos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/cotizaciondocumentos/add', 
				name: 'cotizaciondocumentosadd', 
				component: () => import('./pages/cotizaciondocumentos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/cotizaciondocumentos/edit/:id', 
				name: 'cotizaciondocumentosedit', 
				component: () => import('./pages/cotizaciondocumentos/edit.vue'), 
				props: true
			},
		

//cotizaciones routes
			{
				path: '/cotizaciones/:fieldName?/:fieldValue?',
				name: 'cotizacioneslist',
				component: () => import('./pages/cotizaciones/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/cotizaciones/view/:id', 
				name: 'cotizacionesview', 
				component: () => import('./pages/cotizaciones/view.vue'), 
				props: true
			},
		
			{ 
				path: '/cotizaciones/add', 
				name: 'cotizacionesadd', 
				component: () => import('./pages/cotizaciones/add.vue'), 
				props: true
			},
	
			{ 
				path: '/cotizaciones/edit/:id', 
				name: 'cotizacionesedit', 
				component: () => import('./pages/cotizaciones/edit.vue'), 
				props: true
			},
		
			{ 
				path: '/cotizaciones/gestiondespachos/:id', 
				name: 'cotizacionesgestiondespachos', 
				component: () => import('./pages/cotizaciones/gestiondespachos.vue'), 
				props: true
			},
		
			{ 
				path: '/cotizaciones/gestionfacturar/:id', 
				name: 'cotizacionesgestionfacturar', 
				component: () => import('./pages/cotizaciones/gestionfacturar.vue'), 
				props: true
			},
		
			{
				path: '/cotizaciones/pendientesdepago/:fieldName?/:fieldValue?',
				name: 'cotizacionespendientesdepago',
				component: () => import('./pages/cotizaciones/pendientesdepago.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{
				path: '/cotizaciones/pendientesdedespacho/:fieldName?/:fieldValue?',
				name: 'cotizacionespendientesdedespacho',
				component: () => import('./pages/cotizaciones/pendientesdedespacho.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{
				path: '/cotizaciones/pendientesdefacturar/:fieldName?/:fieldValue?',
				name: 'cotizacionespendientesdefacturar',
				component: () => import('./pages/cotizaciones/pendientesdefacturar.vue'), 
				props: route => passRouteToProps(route)
			},
	

//cotizacioneshasestados routes
			{
				path: '/cotizacioneshasestados/:fieldName?/:fieldValue?',
				name: 'cotizacioneshasestadoslist',
				component: () => import('./pages/cotizacioneshasestados/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/cotizacioneshasestados/add', 
				name: 'cotizacioneshasestadosadd', 
				component: () => import('./pages/cotizacioneshasestados/add.vue'), 
				props: true
			},
	

//cotizacionestados routes
			{
				path: '/cotizacionestados/:fieldName?/:fieldValue?',
				name: 'cotizacionestadoslist',
				component: () => import('./pages/cotizacionestados/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/cotizacionestados/view/:id', 
				name: 'cotizacionestadosview', 
				component: () => import('./pages/cotizacionestados/view.vue'), 
				props: true
			},
		
			{ 
				path: '/cotizacionestados/edit/:id', 
				name: 'cotizacionestadosedit', 
				component: () => import('./pages/cotizacionestados/edit.vue'), 
				props: true
			},
		

//cotizacionpagos routes
			{
				path: '/cotizacionpagos/:fieldName?/:fieldValue?',
				name: 'cotizacionpagoslist',
				component: () => import('./pages/cotizacionpagos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/cotizacionpagos/view/:id', 
				name: 'cotizacionpagosview', 
				component: () => import('./pages/cotizacionpagos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/cotizacionpagos/add', 
				name: 'cotizacionpagosadd', 
				component: () => import('./pages/cotizacionpagos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/cotizacionpagos/edit/:id', 
				name: 'cotizacionpagosedit', 
				component: () => import('./pages/cotizacionpagos/edit.vue'), 
				props: true
			},
		

//cotizacionproductos routes
			{
				path: '/cotizacionproductos/:fieldName?/:fieldValue?',
				name: 'cotizacionproductoslist',
				component: () => import('./pages/cotizacionproductos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/cotizacionproductos/view/:id', 
				name: 'cotizacionproductosview', 
				component: () => import('./pages/cotizacionproductos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/cotizacionproductos/add', 
				name: 'cotizacionproductosadd', 
				component: () => import('./pages/cotizacionproductos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/cotizacionproductos/edit/:id', 
				name: 'cotizacionproductosedit', 
				component: () => import('./pages/cotizacionproductos/edit.vue'), 
				props: true
			},
		

//documentacion routes
			{
				path: '/documentacion/:fieldName?/:fieldValue?',
				name: 'documentacionlist',
				component: () => import('./pages/documentacion/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/documentacion/view/:id', 
				name: 'documentacionview', 
				component: () => import('./pages/documentacion/view.vue'), 
				props: true
			},
		
			{ 
				path: '/documentacion/add', 
				name: 'documentacionadd', 
				component: () => import('./pages/documentacion/add.vue'), 
				props: true
			},
	
			{ 
				path: '/documentacion/edit/:id', 
				name: 'documentacionedit', 
				component: () => import('./pages/documentacion/edit.vue'), 
				props: true
			},
		

//formulas routes

//geociudades routes
			{
				path: '/geociudades/:fieldName?/:fieldValue?',
				name: 'geociudadeslist',
				component: () => import('./pages/geociudades/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/geociudades/view/:id', 
				name: 'geociudadesview', 
				component: () => import('./pages/geociudades/view.vue'), 
				props: true
			},
		
			{ 
				path: '/geociudades/add', 
				name: 'geociudadesadd', 
				component: () => import('./pages/geociudades/add.vue'), 
				props: true
			},
	
			{ 
				path: '/geociudades/edit/:id', 
				name: 'geociudadesedit', 
				component: () => import('./pages/geociudades/edit.vue'), 
				props: true
			},
		

//geodepartamentos routes
			{
				path: '/geodepartamentos/:fieldName?/:fieldValue?',
				name: 'geodepartamentoslist',
				component: () => import('./pages/geodepartamentos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/geodepartamentos/view/:id', 
				name: 'geodepartamentosview', 
				component: () => import('./pages/geodepartamentos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/geodepartamentos/add', 
				name: 'geodepartamentosadd', 
				component: () => import('./pages/geodepartamentos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/geodepartamentos/edit/:id', 
				name: 'geodepartamentosedit', 
				component: () => import('./pages/geodepartamentos/edit.vue'), 
				props: true
			},
		

//geopaises routes
			{
				path: '/geopaises/:fieldName?/:fieldValue?',
				name: 'geopaiseslist',
				component: () => import('./pages/geopaises/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/geopaises/view/:id', 
				name: 'geopaisesview', 
				component: () => import('./pages/geopaises/view.vue'), 
				props: true
			},
		
			{ 
				path: '/geopaises/add', 
				name: 'geopaisesadd', 
				component: () => import('./pages/geopaises/add.vue'), 
				props: true
			},
	
			{ 
				path: '/geopaises/edit/:id', 
				name: 'geopaisesedit', 
				component: () => import('./pages/geopaises/edit.vue'), 
				props: true
			},
		

//metodospago routes
			{
				path: '/metodospago/:fieldName?/:fieldValue?',
				name: 'metodospagolist',
				component: () => import('./pages/metodospago/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/metodospago/view/:id', 
				name: 'metodospagoview', 
				component: () => import('./pages/metodospago/view.vue'), 
				props: true
			},
		
			{ 
				path: '/metodospago/add', 
				name: 'metodospagoadd', 
				component: () => import('./pages/metodospago/add.vue'), 
				props: true
			},
	
			{ 
				path: '/metodospago/edit/:id', 
				name: 'metodospagoedit', 
				component: () => import('./pages/metodospago/edit.vue'), 
				props: true
			},
		

//ordenesestados routes
			{
				path: '/ordenesestados/:fieldName?/:fieldValue?',
				name: 'ordenesestadoslist',
				component: () => import('./pages/ordenesestados/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/ordenesestados/view/:id', 
				name: 'ordenesestadosview', 
				component: () => import('./pages/ordenesestados/view.vue'), 
				props: true
			},
		
			{ 
				path: '/ordenesestados/edit/:id', 
				name: 'ordenesestadosedit', 
				component: () => import('./pages/ordenesestados/edit.vue'), 
				props: true
			},
		

//ordeneshasestados routes
			{
				path: '/ordeneshasestados/:fieldName?/:fieldValue?',
				name: 'ordeneshasestadoslist',
				component: () => import('./pages/ordeneshasestados/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/ordeneshasestados/add', 
				name: 'ordeneshasestadosadd', 
				component: () => import('./pages/ordeneshasestados/add.vue'), 
				props: true
			},
	

//ordeneshasimagenes routes
			{
				path: '/ordeneshasimagenes/:fieldName?/:fieldValue?',
				name: 'ordeneshasimageneslist',
				component: () => import('./pages/ordeneshasimagenes/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/ordeneshasimagenes/add', 
				name: 'ordeneshasimagenesadd', 
				component: () => import('./pages/ordeneshasimagenes/add.vue'), 
				props: true
			},
	
			{ 
				path: '/ordeneshasimagenes/edit/:id', 
				name: 'ordeneshasimagenesedit', 
				component: () => import('./pages/ordeneshasimagenes/edit.vue'), 
				props: true
			},
		

//ordeneshasprocesos routes
			{
				path: '/ordeneshasprocesos/:fieldName?/:fieldValue?',
				name: 'ordeneshasprocesoslist',
				component: () => import('./pages/ordeneshasprocesos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/ordeneshasprocesos/view/:id', 
				name: 'ordeneshasprocesosview', 
				component: () => import('./pages/ordeneshasprocesos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/ordeneshasprocesos/add', 
				name: 'ordeneshasprocesosadd', 
				component: () => import('./pages/ordeneshasprocesos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/ordeneshasprocesos/edit/:id', 
				name: 'ordeneshasprocesosedit', 
				component: () => import('./pages/ordeneshasprocesos/edit.vue'), 
				props: true
			},
		

//ordenesproduccion routes
			{
				path: '/ordenesproduccion/:fieldName?/:fieldValue?',
				name: 'ordenesproduccionlist',
				component: () => import('./pages/ordenesproduccion/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/ordenesproduccion/view/:id', 
				name: 'ordenesproduccionview', 
				component: () => import('./pages/ordenesproduccion/view.vue'), 
				props: true
			},
		
			{ 
				path: '/ordenesproduccion/edit/:id', 
				name: 'ordenesproduccionedit', 
				component: () => import('./pages/ordenesproduccion/edit.vue'), 
				props: true
			},
		

//permissions routes
			{
				path: '/permissions/:fieldName?/:fieldValue?',
				name: 'permissionslist',
				component: () => import('./pages/permissions/list.vue'), 
				props: route => passRouteToProps(route)
			},
	

//permissionslist routes

//procesosinternos routes
			{
				path: '/procesosinternos/:fieldName?/:fieldValue?',
				name: 'procesosinternoslist',
				component: () => import('./pages/procesosinternos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/procesosinternos/view/:id', 
				name: 'procesosinternosview', 
				component: () => import('./pages/procesosinternos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/procesosinternos/add', 
				name: 'procesosinternosadd', 
				component: () => import('./pages/procesosinternos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/procesosinternos/edit/:id', 
				name: 'procesosinternosedit', 
				component: () => import('./pages/procesosinternos/edit.vue'), 
				props: true
			},
		

//productoimagenes routes
			{
				path: '/productoimagenes/:fieldName?/:fieldValue?',
				name: 'productoimageneslist',
				component: () => import('./pages/productoimagenes/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/productoimagenes/add', 
				name: 'productoimagenesadd', 
				component: () => import('./pages/productoimagenes/add.vue'), 
				props: true
			},
	
			{ 
				path: '/productoimagenes/edit/:id', 
				name: 'productoimagenesedit', 
				component: () => import('./pages/productoimagenes/edit.vue'), 
				props: true
			},
		

//productoprecios routes
			{
				path: '/productoprecios/:fieldName?/:fieldValue?',
				name: 'productoprecioslist',
				component: () => import('./pages/productoprecios/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/productoprecios/add', 
				name: 'productopreciosadd', 
				component: () => import('./pages/productoprecios/add.vue'), 
				props: true
			},
	
			{ 
				path: '/productoprecios/edit/:id', 
				name: 'productopreciosedit', 
				component: () => import('./pages/productoprecios/edit.vue'), 
				props: true
			},
		

//productos routes
			{
				path: '/productos/:fieldName?/:fieldValue?',
				name: 'productoslist',
				component: () => import('./pages/productos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/productos/view/:id', 
				name: 'productosview', 
				component: () => import('./pages/productos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/productos/add', 
				name: 'productosadd', 
				component: () => import('./pages/productos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/productos/edit/:id', 
				name: 'productosedit', 
				component: () => import('./pages/productos/edit.vue'), 
				props: true
			},
		

//roles routes
			{
				path: '/roles/:fieldName?/:fieldValue?',
				name: 'roleslist',
				component: () => import('./pages/roles/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/roles/view/:id', 
				name: 'rolesview', 
				component: () => import('./pages/roles/view.vue'), 
				props: true
			},
		
			{ 
				path: '/roles/add', 
				name: 'rolesadd', 
				component: () => import('./pages/roles/add.vue'), 
				props: true
			},
	
			{ 
				path: '/roles/edit/:id', 
				name: 'rolesedit', 
				component: () => import('./pages/roles/edit.vue'), 
				props: true
			},
		
			{ 
				path: '/roles/asignar_permisos/:id', 
				name: 'rolesasignar_permisos', 
				component: () => import('./pages/roles/asignar_permisos.vue'), 
				props: true
			},
		

//secciones routes
			{
				path: '/secciones/:fieldName?/:fieldValue?',
				name: 'seccioneslist',
				component: () => import('./pages/secciones/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/secciones/view/:id', 
				name: 'seccionesview', 
				component: () => import('./pages/secciones/view.vue'), 
				props: true
			},
		
			{ 
				path: '/secciones/add', 
				name: 'seccionesadd', 
				component: () => import('./pages/secciones/add.vue'), 
				props: true
			},
	
			{ 
				path: '/secciones/edit/:id', 
				name: 'seccionesedit', 
				component: () => import('./pages/secciones/edit.vue'), 
				props: true
			},
		

//sedes routes
			{
				path: '/sedes/:fieldName?/:fieldValue?',
				name: 'sedeslist',
				component: () => import('./pages/sedes/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/sedes/view/:id', 
				name: 'sedesview', 
				component: () => import('./pages/sedes/view.vue'), 
				props: true
			},
		
			{ 
				path: '/sedes/add', 
				name: 'sedesadd', 
				component: () => import('./pages/sedes/add.vue'), 
				props: true
			},
	
			{ 
				path: '/sedes/edit/:id', 
				name: 'sedesedit', 
				component: () => import('./pages/sedes/edit.vue'), 
				props: true
			},
		

//seguimientos routes
			{
				path: '/seguimientos/:fieldName?/:fieldValue?',
				name: 'seguimientoslist',
				component: () => import('./pages/seguimientos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/seguimientos/view/:id', 
				name: 'seguimientosview', 
				component: () => import('./pages/seguimientos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/seguimientos/add', 
				name: 'seguimientosadd', 
				component: () => import('./pages/seguimientos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/seguimientos/edit/:id', 
				name: 'seguimientosedit', 
				component: () => import('./pages/seguimientos/edit.vue'), 
				props: true
			},
		

//subsecciones routes
			{
				path: '/subsecciones/:fieldName?/:fieldValue?',
				name: 'subseccioneslist',
				component: () => import('./pages/subsecciones/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/subsecciones/view/:id', 
				name: 'subseccionesview', 
				component: () => import('./pages/subsecciones/view.vue'), 
				props: true
			},
		
			{ 
				path: '/subsecciones/add', 
				name: 'subseccionesadd', 
				component: () => import('./pages/subsecciones/add.vue'), 
				props: true
			},
	
			{ 
				path: '/subsecciones/edit/:id', 
				name: 'subseccionesedit', 
				component: () => import('./pages/subsecciones/edit.vue'), 
				props: true
			},
		

//tipodocumentos routes
			{
				path: '/tipodocumentos/:fieldName?/:fieldValue?',
				name: 'tipodocumentoslist',
				component: () => import('./pages/tipodocumentos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/tipodocumentos/view/:id', 
				name: 'tipodocumentosview', 
				component: () => import('./pages/tipodocumentos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/tipodocumentos/add', 
				name: 'tipodocumentosadd', 
				component: () => import('./pages/tipodocumentos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/tipodocumentos/edit/:id', 
				name: 'tipodocumentosedit', 
				component: () => import('./pages/tipodocumentos/edit.vue'), 
				props: true
			},
		

//tipoproductos routes
			{
				path: '/tipoproductos/:fieldName?/:fieldValue?',
				name: 'tipoproductoslist',
				component: () => import('./pages/tipoproductos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/tipoproductos/view/:id', 
				name: 'tipoproductosview', 
				component: () => import('./pages/tipoproductos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/tipoproductos/add', 
				name: 'tipoproductosadd', 
				component: () => import('./pages/tipoproductos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/tipoproductos/edit/:id', 
				name: 'tipoproductosedit', 
				component: () => import('./pages/tipoproductos/edit.vue'), 
				props: true
			},
		

//transportadoras routes
			{
				path: '/transportadoras/:fieldName?/:fieldValue?',
				name: 'transportadoraslist',
				component: () => import('./pages/transportadoras/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/transportadoras/view/:id', 
				name: 'transportadorasview', 
				component: () => import('./pages/transportadoras/view.vue'), 
				props: true
			},
		
			{ 
				path: '/transportadoras/add', 
				name: 'transportadorasadd', 
				component: () => import('./pages/transportadoras/add.vue'), 
				props: true
			},
	
			{ 
				path: '/transportadoras/edit/:id', 
				name: 'transportadorasedit', 
				component: () => import('./pages/transportadoras/edit.vue'), 
				props: true
			},
		

//usuarios routes
			{
				path: '/usuarios/:fieldName?/:fieldValue?',
				name: 'usuarioslist',
				component: () => import('./pages/usuarios/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/usuarios/view/:id', 
				name: 'usuariosview', 
				component: () => import('./pages/usuarios/view.vue'), 
				props: true
			},
		
			{ 
				path: '/account/edit', 
				name: 'usuariosaccountedit', 
				component: () => import('./pages/account/accountedit.vue'), 
				props: true
			},
	
			{ 
				path: '/account', 
				name: 'usuariosaccountview', 
				component: () => import('./pages/account/accountview.vue'), 
				props: true
			},
	
			{ 
				path: '/usuarios/add', 
				name: 'usuariosadd', 
				component: () => import('./pages/usuarios/add.vue'), 
				props: true
			},
	
			{ 
				path: '/usuarios/edit/:id', 
				name: 'usuariosedit', 
				component: () => import('./pages/usuarios/edit.vue'), 
				props: true
			},
		

			{ 
				path: '/calcularcotizacion', 
				name: 'calcularcotizacion', 
				component: () => import('././pages/custom/calcularcotizacion.vue'), 
				props: true
			},
	
			{ 
				path: '/controlsecciones', 
				name: 'controlsecciones', 
				component: () => import('././pages/custom/controlsecciones.vue'), 
				props: true
			},
	
			{ 
				path: '/instructivos', 
				name: 'instructivos', 
				component: () => import('././pages/custom/instructivos.vue'), 
				props: true
			},
	
			{ 
				path: '/consultapublicaorden', 
				name: 'consultapublicaorden', 
				component: () => import('././pages/custom/consultapublicaorden.vue'), 
				props: true
			},
	
	
	
//Password reset routes
			{ 
				path: '/index/forgotpassword', 
				name: 'forgotpassword', 
				component: () => import('./pages/index/forgotpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword', 
				name: 'resetpassword', 
				component: () => import('./pages/index/resetpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword_completed', 
				name: 'resetpassword_completed', 
				component: () => import('./pages/index/resetpassword_completed.vue'), 
				props: true
			},
	
	
	
	{ 
		path:  '/error/forbidden', 
		name: 'forbidden', 
		component: () => import('./pages/errors/forbidden.vue'),
		props: true
	},
	{ 
		path: '/error/notfound', 
		name: 'notfound',
		component: () => import('./pages/errors/pagenotfound.vue'),
		props: true
	},
	{
		path: '/:catchAll(.*)', 
		component: () => import('./pages/errors/pagenotfound.vue')
	}
];

export default () => {
	
const auth = useAuth();

	
	const user = auth.user;
	if(user){
		routes.push({ path: '/', alias: '/home', name: 'home', component: () => import(`./pages/home/home.vue`) });
	}
	else{
		routes.push({ path: '/', alias: '/index', name: 'index', component: () => import('./pages/index/index.vue') });
	}

	const router = createRouter({
		history: createWebHashHistory(),
		routes,
		scrollBehavior(to, from, savedPostion){
			if(savedPostion) return savedPostion;
			return { top:0 }
		}
	});
	
	router.beforeEach((to, from, next) => {
		const user = auth.user;
		let path = to.path;
		let authRequired = auth.pageRequiredAuth(path);
		if (authRequired) {
			if(!user){
				return next({ path: '/',  query: { nexturl: to.fullPath } });
			}
			//authorize user
			if (!auth.canView(path)) {
				return next({path: "/error/forbidden"});
			}
		}

		if(user && to.name == "index"){
			//already logged in, show home when try to access index page
			return next({ path: "/home"});
		}

		//navigate to redirect url if available
		if(to.name == "home" && to.query.nexturl){
			return next({ path: to.query.nexturl});
		}

 		//close dialog from previous page
		//store.closeDialogs('app/closeDialogs');
		
		next();
	});

	return router;
}
